import React from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from "@tanstack/react-query";
import {Link} from 'react-router-dom';

const JobDetails = () => {
    const { slug } = useParams()

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["jobDataSingle"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/career/detail/${slug}`)
            .then((res) => res.data),
      });

    if (isFetching) return(
        <div style={{minHeight: '90vh'}}></div>
      );
    
      if (isLoading) return(
        <div style={{minHeight: '90vh'}}></div>
      );

    if (error) return "An error has occurred: " + error.message;

  return (
    <>
        {/* <section className="insite_page_banner">
            <h1>Career</h1>
        </section>   */}

        <main id="main">
            <section className="inner-page" style={{ marginTop:'75px' }}>
            <div className="container">
                <div className="row">
                <div className="col-sm-3">
                    <div className="job-titless">
                    <h2>Job details</h2>
                        <ul>
                        <li>Country : <span>{data.detail.country}</span> </li>
                        <li>State : <span>{data.detail.state}</span> </li>
                        <li>City : <span>{data.detail.city}</span> </li>
                        <li>Company : <span>{data.detail.company}</span> </li>
                        <li>Expiry Date : <span>{data.detail.expiry_date}</span> </li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="job-details-title">
                    <h2>Job Description</h2>
                    <Link to={ 
                        `/apply/${data.detail.id}`
                    } className="apply-btn">Apply now</Link>
                    </div>
                    <div className="job-detailss">
                        <div className='CKContent' dangerouslySetInnerHTML={{ __html: data.detail.description }}>

                        </div>

                    <p style={{ marginTop: '24px' }}>
                        Prisa Consulting Services LLC is an equal opportunity employer and all qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity or expression, pregnancy, age, national origin, disability status, genetic information, protected veteran status, or any other characteristic protected by law."
                    </p>
                    <p>
                        Prisa Consulting Services LLC will endeavor to make a reasonable accommodation to the known physical or mental limitations of a qualified applicant with a disability unless the accommodation would impose an undue hardship on the operation of our business. If you believe you require such assistance to complete this form or to participate in an interview, please contact us at contact@prisallc.com or call +1-817-663-1974 between 9:00 AM CST to 5:00 PM CST Monday to Friday.
                    </p>
                    </div>
                    <div className="apply-box" style={{ marginTop: '26px' }}>
                        <Link to={ 
                        `/apply/${data.detail.id}`
                    }  className="apply-btn">Apply now</Link>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </main>
    </>
  )
}

export default JobDetails