import React,{useMemo, useEffect, useState} from 'react'
import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'
import { useNavigate, Link  } from 'react-router-dom';
// import css from '../assets/img/css.png'
// import client01 from '../assets/img/clients/client-1.png'
// import client02 from '../assets/img/clients/client-2.png'
// import client03 from '../assets/img/clients/client-3.png'
// import client04 from '../assets/img/clients/client-4.png'
// import client05 from '../assets/img/clients/client-5.png'
// import js from '../assets/img/js.png'
// import py from '../assets/img/py.jfif'

const Career = () => {

  const { isLoading:filterLoading, error:filterError, data:data, isFetching:filterFetching } = useQuery({
    queryKey: ["careerFilterData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/career/filter_data`)
        .then((res) => res.data),
  });

  const { isLoading:jobsLoading, error:jobsError, data:jobsData, isFetching:jobsFetching } = useQuery({
    queryKey: ["careerJobsData"],
    queryFn: () =>
      axios 
        .get(`${process.env.REACT_APP_BASE_URL}/career`)
        .then((res) => res.data),
  });

  const navigate = useNavigate();

  const [isResetFilter, setIsResetFilter] = useState(false);
  console.log("isResetFilter =>", isResetFilter)
  const [searchQuery, setSearchQuery] = useState();
  const [tempFiltered, setTempFiltered] = useState();
  // console.log("tempFiltered =>", typeof tempFiltered, tempFiltered)
  const [filterConditions, setFilterConditions] = useState({});
  console.log("filterConditions =>", typeof filterConditions, filterConditions);
  const [ countries, setContries ] = useState();
  const [ states, setStates ] = useState();
  const [ cities, setCities ] = useState();
  const [ companies, setCompanies ] = useState();

  const [ isCountriesDisabled, setIsCountriesDisabled ] = useState(false);
  const [ isStatesDisabled, setIsStatesDisabled ] = useState(true);
  const [ isCitiesDisabled, setIsCitiesDisabled ] = useState(true);

  const [ isCompaniesDisabled, setIsCompaniesDisabled ] = useState(true);

  const [selectedOption, setSelectedOption] = useState('');
  console.log("selectedOption =>", typeof selectedOption, selectedOption);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedCompany, setSelectedCompany] = useState(); 
  
  console.log("selectedCountry =>", typeof selectedCountry, selectedCountry);


  useEffect(() => {
    let countryOpt = data?.data?.map(({id, title}) => ({
      value: id,
      label: title
    }))

    let stateOpt = data?.data?.map((item) => (
      item?.states.map((i) => ({
        value: i.id,
        label: i.title
      }))
    ))

    let stateFlat = stateOpt?.flat(1)

    let cityOpt = data?.data?.map((item) => (
      item?.states.map((i) => (
        i.cities.map((a) => ({
            value: a.id,
            label: a.name
        }))
      ))
    ))

    let cityFlat = cityOpt?.flat(2)

    let companyOpt = data?.data?.map((item) => (
      item?.states.map((i) => (
        i.cities.map((a) => (
            a.companies.map((b) => ({
              value: b.id,
              label: b.name
            }))
        ))
      ))
    ))

    let companyFlat = companyOpt?.flat(3)
    
    setContries(() => countryOpt);
    setStates(() => stateFlat);
    setCities(() => cityFlat);
    setCompanies(() => companyFlat);
  }, [data])

  useEffect(() => {
    switch (selectedOption) {
      case 'country':
        {
          // console.log('country selected');
          // selectedCountry

          // countryInputRef.current.select.clearValue();
          // onStateClear();
          // onCityClear();
          // onCompanyClear();

          const found = data?.data.find((item) => item.id === selectedCountry);
          // console.log("found =>", typeof found, found);

          setTempFiltered(() => found);

            let stateOpt = found?.states.map((i) => ({
              value: i.id,
              label: i.title
            }))

            let cityOpt = found?.states.map((i) => (
              i.cities.map((i) => ({
                  value: i.id,
                  label: i.name
              }))
            ))

            let cityFlat = cityOpt?.flat(1)

            let companyOpt = found?.states.map((item) => (
              item?.cities.map((i) => (
                i.companies.map((a) => ({
                    value: a.id,
                    label: a.name
                }))
              ))
            ))
        
            let companyFlat = companyOpt?.flat(2)

            setStates(() => stateOpt);
            setCities(() => cityFlat);
            setCompanies(() => companyFlat);

            setIsCountriesDisabled(true)
            setIsStatesDisabled(false)

            setFilterConditions({"country_id": selectedCountry})
        }

        break;
      case 'state':
        {
          const found = tempFiltered?.states.find((item) => item.id === selectedState)

          setTempFiltered(() => found);

          let cityOpt = found?.cities.map((i) => (
            {
                value: i.id,
                label: i.name
            }
          ))          

          let companyOpt = found?.cities?.map((i) => (
              i.companies.map((item) => ({
                  value: item.id,
                  label: item.name
              }))
          ))

          let companyFlat = companyOpt?.flat(1)

          setCities(() => cityOpt);
          setCompanies(() => companyFlat);

             setIsCountriesDisabled(true)
             setIsStatesDisabled(true)
             setIsCitiesDisabled(false)

             setFilterConditions({"state_id": selectedState})
        }
        break;
        case 'city':
          {
            const found = tempFiltered?.cities.find((item) => item.id === selectedCity)

            setTempFiltered(() => found);

            let companyOpt = found?.companies.map((i) => (
              {
                  value: i.id,
                  label: i.name
              }
            ))  

            setCompanies(() => companyOpt);

            setIsCitiesDisabled(true);
            setIsCompaniesDisabled(false);

            setFilterConditions({"city_id": selectedCity})
          }
          break;
          case 'company':
            setFilterConditions({"company_id": selectedCompany})
          break;
          case 'reset': {
            let countryOpt = data?.data?.map(({id, title}) => ({
              value: id,
              label: title
            }))

            setContries(() => countryOpt);
            setFilterConditions({});
            setIsCountriesDisabled(false);
            setIsStatesDisabled(true);
            setIsCitiesDisabled(true);
            setIsCompaniesDisabled(true);
          }
      default:
        console.log(`Sorry, we are out.`);
    }
  }, [selectedOption, data])

  function resetOnSearch () {
    let countryOpt = data?.data?.map(({id, title}) => ({
      value: id,
      label: title
    }))

      setContries(() => countryOpt);
      setFilterConditions({});
      setIsCountriesDisabled(false);
      setIsStatesDisabled(true);
      setIsCitiesDisabled(true);
      setIsCompaniesDisabled(true);
  }

  var filterReset = useMemo(resetOnSearch, [searchQuery])

  console.log("filterReset =>", typeof filterReset, filterReset)

  // Function to get filtered list
  function getSearchList() {
    return jobsData?.careers.filter((s) => s.country.toLowerCase().includes(searchQuery) || s.state.toLowerCase().includes(searchQuery) || s.title.toLowerCase().includes(searchQuery) || s.city.toLowerCase().includes(searchQuery) || s.company.toLowerCase().includes(searchQuery))
  }

  var searchList = useMemo(getSearchList, [searchQuery])

  console.log("searchList =>", typeof searchList, searchList)

    // Function to get filtered list
    function getFilteredList() {
      return jobsData?.careers.filter(item => {
        return Object.keys(filterConditions).every(key => {
          return filterConditions[key] === item[key];
        });
      });

    }
  // Avoid duplicate function calls with useMemo
  var filteredList = useMemo(getFilteredList, [filterConditions]);

  console.log("filteredList =>", typeof filteredList, filteredList);

  if (filterLoading || jobsLoading) return(
    <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
  );

  if (filterError || jobsError) return "An error has occurred: " + filterError.message + jobsError.message;

  return (
    <>
    <section className="insite_page_banner">
    <h1>Career</h1>
</section>  

<main id="main">
    <section className="inner-page">
      <div className="container">
        <div className="row">
        	<div className="col-sm-9 order-2 order-lg-1">
            <div className="search-input">
              <input type="text" name="search" placeholder="Search Job" onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} />
            </div> 
            <div className="row">            

            {searchQuery?.length > 0 ? (
                <>
                {searchList.length > 0 ? (
                  <>
                  {searchList.map((i, index) => (
                    <div onClick={() => navigate(`/career/${i.slug}`)} key={index} className="col-sm-6">
                    <div className="job-box">
                      <div className="job-img">
                          <img src={i.company_profile_img} alt={i.slug} />
                      </div>
                      <div className="job-title">
                        <Link to={`/career/${i.slug}`}>{i.title}</Link>
                        <p>{i.city}, {i.country}</p>
                        <p>{i.company}</p>
                        <span>{i.expiry_date}</span>
                      </div>
                    </div>
                  </div>
                ))}
                </>
                ) : (
                  <>
                    <h2>No Dataa</h2>
                    {/* <pre>{JSON.stringify(searchList, null, 2) }</pre> */}
                  </>
                )}
              </> 
            ) : (
              <>
                {Object.keys(filterConditions).length !== 0 ? (
                <>
                  {filteredList.length > 0 ? (
                    <>
                    {filteredList.map((i, index) => (
                      <div onClick={() => navigate(`/career/${i.slug}`)} key={index} className="col-sm-6">
                      <div className="job-box">
                        <div className="job-img">
                            <img src={i.company_profile_img} alt={i.slug} />
                        </div>
                        <div className="job-title">
                          <Link to={`/career/${i.slug}`}>{i.title}</Link>
                          <p>{i.city}, {i.country}</p>
                          <p>{i.company}</p>
                          <span>{i.expiry_date}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                  </>
                  ) : (
                    <h2>No Data</h2>
                  )}
                </>
            ) : (
              jobsData.careers.map((i, index) => (
                <div onClick={() => navigate(`/career/${i.slug}`)} key={index} className="col-sm-6">
                <div className="job-box">
                  <div className="job-img">
                      <img src={i.company_profile_img} alt={i.slug} />
                  </div>
                  <div className="job-title">
                    <Link to={`/career/${i.slug}`}>{i.title}</Link>
                    <p>{i.city}, {i.country}</p>
                    <p>{i.company}</p>
                    <span>{i.expiry_date}</span>
                  </div>
                </div>
              </div>
              ))
            )}
              </>
            )}
            


            


              
            </div>
        	</div>
        	<div className="col-sm-3 order-1 order-lg-2">
            <div className="job-filters">
              <h3 className='filter-header'>Filters</h3>
              <div className='careerResetContainer'>
                  {/* <div onClick={() => setSelectedOption('reset')} className='resetButton'>
                  <p>Reset</p>
                  </div> */}

                  <button onClick={() => setSelectedOption('reset')} className="button-34" role="button">Reset</button>
              </div>
              
              <div className='careerPageSelectContainer'>
                <label>Country</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select1"
                        isDisabled={isCountriesDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="country"
                        options={countries&&countries}
                        onChange={(selected) => {setSelectedCountry(selected.value); setSelectedOption('country');}}
                      />
              </div>

              <div className='careerPageSelectContainer'>
                <label>State</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select1"
                        isDisabled={isStatesDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="state"
                        options={states&&states}
                        onChange={(selected) => {setSelectedState(selected.value); setSelectedOption('state');}}
                      />
              </div>

              <div className='careerPageSelectContainer'>
                <label>City</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select1"
                        isDisabled={isCitiesDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="city"
                        options={cities&&cities}
                        onChange={(selected) => {setSelectedCity(selected.value); setSelectedOption('city');}}
                      />
              </div>

              <div className='careerPageSelectContainer'>
                <label>Company</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select1"
                        isDisabled={isCompaniesDisabled}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="companies"
                        options={companies&&companies}
                        onChange={(selected) => {setSelectedCompany(selected.value); setSelectedOption('company');}}
                      />
              </div>

            </div> 
        	</div>
        </div>
      </div>
    </section>
</main>
</>
  )
}

export default Career