import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const Notification = ({success, msg}) => {
    const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant={success?'success':'danger'} onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
            {success?
                'You got an Success!'
                :'Oh snap! You got an error!'}
        </Alert.Heading>
        <p>
          {msg}
        </p>
      </Alert>
    );
  }
  return;
}

export default Notification
