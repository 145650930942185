import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const FaqHome = ({data}) => {
  return (
    <section id="tech" className='services tech'>
    <div className='container'>
    {/* <div className="section-title" data-aos="fade-up">
      <h1 className="main_title" style={{ textAlign: 'center', lineHeight: '5px' }}> {window.innerWidth > 768 ? 'Technology We Use' : "Technologies"}</h1>
    </div> */}

    <div className="section-title" data-aos="fade-up">
          <h1 className="main_title">Technologies We Use...</h1>
          <p>
            PCS is directed towards IT initiatives that companies wish to take up in the quest to sustain and grow the business. With specialist knowledge and experience across many different market sectors, PCS can confidently deliver the right solution for you – now and for the future.
          </p>
        </div>

  {/* <div className='row'>
  <Accordion defaultActiveKey={0}>

    {[...data].reverse().map((i, index) => 
          <Accordion.Item key={index} eventKey={index}>
          <Accordion.Header>{i.question}</Accordion.Header>
          <Accordion.Body dangerouslySetInnerHTML={{ __html: i.answer }}>
            
          </Accordion.Body>
        </Accordion.Item>
    )}

    </Accordion>
    </div> */}

<div className='tech-grid-container'>
    {data.map((i) =>
          <div key={i.id} className='grid-item'>
            <img src={i.featured_image} alt={i.slug} />
            <h3>{i.title}</h3>
          </div>
    )}
</div>

    </div>
    </section>
  )
}

export default FaqHome