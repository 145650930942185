import React from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from "@tanstack/react-query";

const BlogDetail = () => {
    const { slug } = useParams()

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["blogDataSingle"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/blog/detail/${slug}`)
            .then((res) => res.data),
      });

    if (isFetching) return(
        <div style={{minHeight: '90vh'}}></div>
      );
    
      if (isLoading) return(
        <div style={{minHeight: '90vh'}}></div>
      );

    if (error) return "An error has occurred: " + error.message;
  return (
    <div className='serviceDetailsPage'>    
    <h2>{data.detail.title}</h2>
    <img src={data.detail.featured_image} alt={data.detail.slug} />

    <div className='serviceCKContent' dangerouslySetInnerHTML={{ __html: data.detail.description }}  >

    </div>
    </div>
  )
}

export default BlogDetail