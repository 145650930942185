import React from 'react'
import client1 from '../assets/img/clients/client-1.png'
import client2 from '../assets/img/clients/client-2.png'
import client3 from '../assets/img/clients/client-3.png'
import client4 from '../assets/img/clients/client-4.png'
import client5 from '../assets/img/clients/client-5.png'
import client6 from '../assets/img/clients/client-6.png'
import Slider from "react-slick";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ClientComp = ({data}) => {

  const clientsSettings = {
    dots: false,
    arrows: false,
    infinite: true, 
    swipeToSlide: true,
    touchThreshold: 100,
    pauseOnHover: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  
  };

  return (
    <section id="clients" className="clients clients">
    <div className="container">
      <div className="section-title" data-aos="fade-up">
        <h1 className="main_title">Our happy clients</h1>
      </div>
      {/* <div className="clients-slider swiper" data-aos="fade-up" data-aos-delay="100"> */}
      <Slider {...clientsSettings}>
          {data.map((i, index) => (
            <div key={index} className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={i.featured_image} className="img-fluid" alt={i.slug} data-aos="zoom-in" />
            </div>
          </div>
          ))}
        </Slider>
        {/* <!-- <div className="swiper-pagination"></div> --> */}
      {/* </div> */}

    </div>
  </section>
  )
}

export default ClientComp