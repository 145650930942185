import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'
import ClientComp from '../components/ClientComp'

const Clients = () => {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["clientsData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/clients/all`)
        .then((res) => res.data),
  });

  if (isLoading) return(
    <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
<section className="insite_page_banner">
    <h1>Clients</h1>
</section>
<ClientComp data={data.items} />
</>
  )
}

export default Clients