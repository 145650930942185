import React from 'react'
import { useNavigate, Link} from 'react-router-dom'

const CareerHome = ({data}) => {
    const navigate = useNavigate();

  return (
    <section id="career" className="services">
    <div className="container">
      <div className="section-title" data-aos="fade-up">
        <h1 className="main_title" style={{ textAlign: 'center', lineHeight: '5px' }}>Latest Jobs</h1>
      </div>

      <div className='row'>
      {data.map((i, index) => (
          <div onClick={() => navigate(`/career/${i.slug}`)} key={index} className="col-sm-6">
          <div className="job-box" style={{ margin: '12px 0 12px 0',  }}>
            <div className="job-img">
                <img src={i.company_profile_img} alt={i.slug} />
            </div>
            <div className="job-title">
              <Link to={`/career/${i.slug}`}>{i.title}</Link>
              <p>{i.city}, {i.country}</p>
              <p>{i.company}</p>
              <span>{i.expiry_date}</span>
            </div>
          </div>
        </div>
        ))}
      </div>
    </div>
    
    </section>
  )
}

export default CareerHome