import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'

const About = () => {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["aboutData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/about`)
        .then((res) => res.data),
  });

  if (isLoading) return(
    <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
    <section className="insite_page_banner">
    <h1>About us</h1>
</section>
<main id="main">
    <section className="inner-page">
      <div className="container">
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p> */}

        <div className='CKContent' dangerouslySetInnerHTML={{ __html: data.about_us.description }}></div>
      </div>
    </section>
</main>
</>
  )
}

export default About