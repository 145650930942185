import React,{useState, useEffect} from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from 'react-hook-form';
import {Link} from 'react-router-dom'
import {ClipLoader} from 'react-spinners'
import Notification from './Notification';


const Footer = () => {

  const { register, handleSubmit, reset, formState: { errors }, formState } = useForm();
  const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
  const [formLoading, setFormLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [msg, setMsg] = useState('')

  const onSubmit = async (values) => {
    setFormLoading(true);
    console.log("values =>", values)
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/newsletter`, values);

      if(data?.error){
        // toast.error('There was an error while submitting. Please try again.');
        setIsAlert(true);
        setIsSuccess(false);
        setMsg('There was an error while submitting. Please try again.');
        setFormLoading(false);
      }else {
        // toast.success('Your Message was successfully sent.');
        setIsAlert(true);
        setIsSuccess(true);
        setMsg('Your Message was successfully sent.');
        setFormLoading(false);
      }   

    } catch (err) {
      console.log(err)
      // toast.error('There was an error while submitting. Please try again.');
      setIsAlert(true);
      setIsSuccess(false);
      setMsg('There was an error while submitting. Please try again.');
      setFormLoading(false);
    }

  }

  useEffect(() => {
    if(isAlert){
      const timer = setTimeout(() => setIsAlert(false), 5000);
      return () => clearTimeout(timer);
    }
    return;
  }, [isAlert])

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["siteData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/site_settings`)
        .then((res) => res.data),
  }); 

  // if (isLoading) return(
  //   <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
  // );

  // if (error) return "An error has occurred: " + error.message;


  if (error) return "An error has occurred: " + error.message;

  return (
    <>
    <section id="contact" className="contact">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h1 className="main_title">Get in Touch</h1>
        </div>
        <div className="row">

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="contact-about">
              <h3>{data?.site_settings?.site_name}</h3>
              <p>
                We love our customers, so feel free to call us at {data?.site_settings?.mobile} or e-mail us at {data?.site_settings?.email}
              </p>
              <p className='opening-hour'><span>Opens: </span>{data?.site_settings.opening_hour}</p>

              <div className="social-links">
              {data?.site_settings?.twitter && (
                <a href={data?.site_settings?.twitter} target="_blank" className="twitter"><i className="bi bi-twitter"></i></a>
              )}

                {data?.site_settings?.facebook && (
                  <a href={data?.site_settings?.facebook} target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
                )}

                {data?.site_settings?.instagram && (
                <a href={data?.site_settings?.instagram} target="_blank" className="instagram"><i className="bi bi-instagram"></i></a>
                )}

                {data?.site_settings?.linked_in && (
                <a href={data?.site_settings?.linked_in} target="_blank" className="linkedin"><i className="bi bi-linkedin"></i></a>
                )}

              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
            <div className="info">
              <div>
                <i className="ri-map-pin-line"></i>
                <p>
                  {data?.site_settings?.address}
                </p>
              </div>

              <div>
                <i className="ri-mail-send-line"></i>
                <p>{data?.site_settings?.email}</p>
              </div>

              <div>
                <i className="ri-phone-line"></i>
                <p>{data?.site_settings?.mobile}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56524.10565021395!2d85.2976855195503!3d27.693916670167447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19b0fdbc7517%3A0xcbc86669773a3933!2sNyatapol%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1678253092679!5m2!1sen!2snp" width="100%" height="250" style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
            <div className='newsletterContainer'>
              <h2>Subscribe to our Newsletter</h2>
              {/* <div className="notification-container success-msg">
                <h3>Success</h3>
                <p>Your Email was successfully sent.</p>
              </div> */}
              {isAlert && (
                <Notification 
                  success={isSuccess}
                  msg={msg}
                />
              )}
              
            <form className='newsletterForm' onSubmit={handleSubmit(onSubmit)}>
            <div className='newsletterFormInputContainer'>
                <label>Email Address</label>
                <input
                type="text"
                placeholder="john@example.com"
                name="email"
                {...register("email", {
                    required: "Email is Required",
                    pattern: { value: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/, message: "Invalid email address" },
                })}
                />
                <span>{errors.email?.message}</span>
            </div>

            <button 
            className='feedbackFormResetButton feedbackSubmitButton'
            type='submit' disabled={isSubmitting} >
            {formLoading && <ClipLoader size={20} color="#fff"></ClipLoader>}
              Subscribe
            </button>
            </form>

            </div>
          </div>
      
        </div>
      </div>
    </section>

    <footer id="footer">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 text-lg-left text-center">
          <div className="copyright">
            &copy; Copyright <strong>PCS</strong>. All Rights Reserved
          </div>
        </div>
        <div className="col-lg-6">
          <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-conditions">Terms & Conditions</Link>
            
          </nav>
        </div>
      </div>
    </div>
  </footer>
    </>

  )
}

export default Footer