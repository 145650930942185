import React,{useState, useMemo} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from  '../assets/img/logo.webp';
import {GiHamburgerMenu} from 'react-icons/gi';
// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
// import { Link as ScrollLink , animateScroll as scroll, } from 'react-scroll'

const Navbar = () => {

  const navigate = useNavigate()


  const { pathname } = useLocation();
  

  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }

  const [active, setActive] = useState('');

  console.log("active =>", active)

  function activeSwitch () {
    switch (pathname) {
      case "/":
        setActive("home")
      break;
      case "/about":
        setActive("about")
      break;
      case "/about/":
        setActive("about")
      break;
      case "/services":
        setActive("services");
      break;
      case "/services/":
        setActive("services");
      break;
      case "/itri/about-us":
        setActive("about");
      break;
      case "/itri/about-us/":
        setActive("about");
      break;
      case "/blogs":
        setActive("blogs");
      break;
      case "blogs/":
        setActive("blogs");
      break;
      case "/contact-us/":
        setActive("contact");
      break;
      case "/contact-us":
        setActive("contact");
      break;
      case "/technologies":
        setActive("tech");
      break;
      case "/technologies/":
        setActive("tech");
      break;
      case "/career":
        setActive("career");
      break;
      case "/career/":
        setActive("career");
      break;
      default:{
        setActive("");
      }
    }
  }

  var activeMemo = useMemo(activeSwitch, [pathname])


  return (
    <>
    {window.innerWidth > 768 ? (
      <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container d-flex align-items-center justify-content-between">
      <div className="logo">
         <Link to="/">
          <img onClick={() => window.scrollTo(0, 0)} src={logo} alt="logo" className="img-fluid" />
        </Link>
      </div>
      <nav id="navbar" className="navbar">
        <ul>
          <li><Link to="/" className={`nav-link ${active === "home" && 'active'}`}>Home</Link></li>
          <li><Link to="/about" className={`nav-link ${active === "about" && 'active'}`}>About</Link></li>
          <li><Link to="/services" className={`nav-link ${active === "services" && 'active'}`}>Services</Link></li>
          {/* <li><Link to="/clients" className="nav-link">Clients</Link></li> */}
          <li><Link to="/blogs" className={`nav-link ${active === "blogs" && 'active'}`}>Blogs</Link></li>
          <li><Link to="/technologies" className={`nav-link ${active === "tech" && 'active'}`}>Tech</Link></li>
          <li><Link to="/career" className={`nav-link ${active === "career" && 'active'}`}>Career</Link></li>
          
            {/* {location.pathname !== `${'/PCS/v02' || '/PCS/v02/'}` ? (
                <li>
                  <Link to="/PCS/v02/" className='getstarted'>Contact Us</Link>
                </li>
            ) : ( */}
              <li>
                      {/* {location.pathname === "/PCS/v02/" || location.pathname === "/PCS/v02" ? (
                        <ScrollLink className='getstarted' activeClass="active" spy={true} to="contact" smooth={true} offset={-120} duration={500}>Contact Us</ScrollLink>
                      ) : (
                        <div 
                          className='getstarted'
                          onClick={() => {setIsOpen(false); navigate("/PCS/v02");}}
                        >Contact Us</div>
                      )} */}

                      <div className='getstarted' onClick={() => navigate('/contact-us')}>Contact Us</div>
              </li>
            {/* )} */}
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
    ) : (
      <>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className='MobileDrawerContainer'
            >
                <img src={logo} alt="logo" onClick={() => {navigate('/'); setIsOpen(false); }} />

                <div className='mobile-nav-items-container'>
                <Link to="/" className={`nav-link ${active === "home" && 'active'}`} onClick={() => setIsOpen(false)}>Home</Link>

                <Link to="/about" className={`nav-link ${active === "about" && 'active'}`} onClick={() => setIsOpen(false)}>About</Link>

                <Link to="/services" className={`nav-link ${active === "services" && 'active'}`} onClick={() => setIsOpen(false)}>Services</Link>

                {/* <Link to="/clients" className="nav-link" onClick={() => setIsOpen(false)}>Clients</Link> */}

                <Link to="/blogs" className={`nav-link ${active === "blogs" && 'active'}`} onClick={() => setIsOpen(false)}>Blogs</Link>
                <Link to="/technologies" className={`nav-link ${active === "tech" && 'active'}`} onClick={() => setIsOpen(false)}>Technologies</Link>
                <Link to="/career" className={`nav-link ${active === "career" && 'active'}`} onClick={() => setIsOpen(false)}>Career</Link>

                {/* <a className="getstarted scrollto" href="http://localhost/pcs/#contact">Get in Touch</a> */}

                {/* {location.pathname === "/PCS/v02/" || location.pathname === "/PCS/v02" ? (
                <ScrollLink className='getstarted' activeClass="active" spy={true} to="contact" smooth={true} offset={-120} duration={500} onClick={() => setIsOpen(false)}>Contact Us</ScrollLink>
                ) : (
                  <div 
                  className='getstarted'
                  onClick={() => {setIsOpen(false); navigate("/PCS/v02");}}
                  >
                    Contact Us
                  </div>
                )} */}

                  <div 
                  className='getstarted'
                  onClick={() => {setIsOpen(false); navigate("/contact-us");}}
                  >
                    Contact Us
                  </div>


                </div>
            </Drawer>

            <div className="mobileNavImageContainer">
            <Link to="/">
              <img onClick={() => window.scrollTo(0, 0)} className='mobileNavLogoImage' src={logo} alt="logo" />
            </Link>


            <GiHamburgerMenu onClick={toggleDrawer} size="24px" color="3a3a3a" />
            </div>
      </>
    )}
    </>
    

  )
}

export default Navbar