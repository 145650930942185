import React,{useEffect} from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import './App.css';
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import './assets/icons/bootstrap-icons/bootstrap-icons.css';
import './assets/icons/boxicons/css/boxicons.min.css';
import './assets/icons/remixicon/remixicon.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Career from './pages/Career';
import ServiceDetails from './pages/ServiceDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsCondition from './pages/TermsCondition';
import JobDetails from './pages/JobDetails';
import Apply from './pages/Apply';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';
import Contact from './pages/Contact';
import Tech from './pages/Tech';
{/* <link href="assets/dependency/bootstrap-icons/bootstrap-icons.css" rel="stylesheet"> */}
{/* <link href="assets/dependency/boxicons/css/boxicons.min.css" rel="stylesheet"> */}
{/* <link href="assets/dependency/remixicon/remixicon.css" rel="stylesheet"></link> */}


function App() {

  useEffect(() => {
    window.externalJS();
  },[])

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
  }, [])



  return (
    <BrowserRouter> 
    <ScrollToTop />
    <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/career" element={<Career />} />
        <Route path="/services/:slug" element={<ServiceDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blogs/:slug" element={<BlogDetail />} />
        <Route path="/technologies" element={<Tech />} />
        <Route path="/career/:slug" element={<JobDetails />} />
        <Route path="/apply/:id" element={<Apply />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
    <Footer />
    <ToastContainer
    position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
    </BrowserRouter>


  );
}

export default App;
