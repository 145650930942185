import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom'

const Tech = () => {
    const navigate = useNavigate();
  
    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["techData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/technology/all`)
            .then((res) => res.data),
      });
    
      if (isLoading) return(
        <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
      );
    
      if (error) return "An error has occurred: " + error.message;

  return (
    <>
    <section className="insite_page_banner">
    <h1>Technologies We Use</h1>
    </section>

    <div className='main'>
      
        <div className='container mx-auto my-4'>
            {/* <Accordion defaultActiveKey={0}>

            {[...data.faqs].reverse().map((i, index) => 
                <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>{i.question}</Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={{ __html: i.answer }}>
                   
                </Accordion.Body>
                </Accordion.Item>
            )}
            </Accordion> */}

        <div className="section-title" data-aos="fade-up">
          <h1 className="main_title">Technologies We Use...</h1>
          <p>
            PCS is directed towards IT initiatives that companies wish to take up in the quest to sustain and grow the business. With specialist knowledge and experience across many different market sectors, PCS can confidently deliver the right solution for you – now and for the future.
          </p>
        </div>

            <div className='tech-grid-container'>
              {data.items.map((i) =>
                    <div key={i.id} className='grid-item'>
                      <img src={i.featured_image} alt={i.slug} />
                      <h3>{i.title}</h3>
                    </div>
              )}
          </div>
        </div>
    </div>
    </>
  )
}

export default Tech