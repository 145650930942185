import React,{useEffect} from 'react'
import striptags from 'striptags'
import { Link as ScrollLink , animateScroll as scroll, } from 'react-scroll'
// import img1 from '../assets/img/testimonials/img1.png'
// import img2 from '../assets/img/testimonials/img2.png'
// import img3 from '../assets/img/testimonials/img3.png'
import { useNavigate } from 'react-router-dom';

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'

import Slider from "react-slick";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";    
// import "swiper/css/navigation";
// import "swiper/css/scrollbar";
// // import required modules
// import { Autoplay, Pagination, Navigation } from "swiper";
import ClientComp from '../components/ClientComp';
import CareerHome from '../components/CareerHome';
import BlogHome from '../components/BlogHome';
import FaqHome from '../components/FaqHome';

const Home = () => {

  const navigate = useNavigate();

  const reviewSettings = {
    dots: true,
    arrows: false,
    infinite: true, 
    draggable: true,
    swipeToSlide: true,
    touchThreshold: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  
  };


  // useEffect(() => {
  //   window.externalJS();
  // },[])

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["homeData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/home`)
            .then((res) => res.data),
      });
    
      if (isLoading) return(
        <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
      );
    
      if (error) return "An error has occurred: " + error.message;

      // console.log("data =>", data)




  return (
    <>
      <section id="hero" className="d-flex align-items-center">
    <div className="container">
      <div className="row">
      {/* <span className="clred"> */}
        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">{data.banners.Title}</h1>
          <h2 
            data-aos="fade-up" 
            data-aos-delay="400"
            dangerouslySetInnerHTML={{ __html: striptags(data.banners.Description) }}    
        >
            {/* We build identities and experiences to elevate and empower organizations. */}
            
            {/* {data.banners.Title} */}
          </h2>
          <div data-aos="fade-up" data-aos-delay="800">
            <ScrollLink activeClass="active" spy={true} to="services" smooth={true} offset={-50} duration={500} className="btn-get-started">
              EXPLORE<i className="bi-arrow-down-short"></i>
            </ScrollLink>
            
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
          <img src={data.banners.DocPath} className="img-fluid animated" alt="hero" />
        </div>
      </div>
    </div>
  </section>
  <main id="main">
    <section id="about" className="about">
      <div className="container">
        <div className="row content">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="150">
            <div className="" data-aos="fade-right" data-aos-delay="200">
            <img src={data.about_us.featured_image} className="img-fluid animated" alt={data.about_us.slug} />
          </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <h1 className="main_title">{data.about_us.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: striptags(data.about_us.description) }}>
              {/* PCS is one of the leading firms in the area. By combining our expertise, experience and the energy of our staff, each client receives close personal and professional attention. Our high standards, service and specialized staff spell the difference between our outstanding service, and other firms. We make sure that every client is served by the professionalism and expertise of our whole firm. */}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="services" className="services">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h1 className="main_title">What we do...</h1>
          <p>
            PCS is directed towards IT initiatives that companies wish to take up in the quest to sustain and grow the business. With specialist knowledge and experience across many different market sectors, PCS can confidently deliver the right solution for you – now and for the future.
          </p>
        </div>
        <div className="row">
          {data.services.map((i) => (
          <div key={i.id} className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          {/* <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div className="icon"><i className={i.slug === 'software-development'?'bx bxl-dribbble': i.slug === 'client-staffing'?'bx bx-file' : i.slug === 'security-solutions'? 'bx bx-tachometer': i.slug === 'cloud-solutions' ? 'bx bx-world':'bx bx-world'} ></i></div>
            <h4 className="title"><a href="#">{i.title}</a></h4>
            <p className="description" dangerouslySetInnerHTML={{ __html: striptags(i.description) }}>
              
            </p>
          </div> */}
          <div className="services_box" data-aos="fade-up" data-aos-delay="100" onClick={() => navigate(`/services/${i.slug}`)}>
              <div className="services_img">
                  <img src={i.featured_image} alt={i.slug} />
              </div>
              <div className="services_text">
                <h4>
                  {i.title}
                  </h4>
                <p className="description" dangerouslySetInnerHTML={{ __html: striptags(i.description) }}></p>
              </div>
            </div>
          </div>
          ))}


          {/* <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><a href="">Client Staffing</a></h4>
              <p className="description">
                What we offer? Contract, Contract to hire, and Full time staffing services.
              </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4 className="title"><a href="#">Security Solutions</a></h4>
              <p className="description">
                It is important to protect your business against cyber security threats and make the most of the opportunities online. 
              </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="bx bx-world"></i></div>
              <h4 className="title"><a href="#">Cloud Solutions</a></h4>
              <p className="description">
                The most appealing reasons to switch to the cloud is the cost savings, ease of use, increased storage capacity and automation. 
              </p>
            </div>
          </div> */}

        </div>
      </div>
    </section>
    
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2 className="main_title align-center">Reviews</h2>
        </div>

        {/* <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

            {data.reviews.map((i) => (
                <div 
                key={i.id}
                className="swiper-slide">
                <div className="testimonial-wrap">
                <div className="testimonial-item">
                    <img src={i.featured_image} className="testimonial-img" alt={i.slug} />
                    <h3>{i.title}</h3>
                    <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    
                    {striptags(i.description.substring(0, 150))}
                            
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                </div>
                </div>
            </div>
            ))}
          
          </div>
          <div className="swiper-pagination"></div>
        </div> */}


        <Slider
        {...reviewSettings} 
            >
            {data.reviews.map((i) => (
                <div 
                key={i.id}
                className="swiper-slide"
                >
                <div className="testimonial-wrap">
                <div className="testimonial-item">
                    <img src={i.featured_image} className="testimonial-img" alt={i.slug} />
                    <h3>{i.title}</h3>
                    <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    
                    {striptags(i.description.substring(0, 150))}
                            
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                </div>
                </div>
            </div>
            ))}
            </Slider>

      </div>
    </section>

    <CareerHome data={data.careers} />

    <ClientComp data={data.clients} />
    
    <BlogHome data={data.blogs} />

    <FaqHome data={data.technology} />

  </main>
    </>
  )
}

export default Home