import React,{useState, useEffect, useRef} from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import {GoLocation} from 'react-icons/go';
import {BsTelephone} from 'react-icons/bs';
import {MdOutlineEmail} from 'react-icons/md';
// import {toast} from 'react-toastify'
import {ClipLoader} from 'react-spinners'
import { useQuery } from "@tanstack/react-query";
import Notification from '../components/Notification';

const Contact = () => {
    const { register, handleSubmit, reset, formState: { errors }, formState } = useForm();
    const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
    const [formLoading, setFormLoading] = useState(false);

    const [isAlert, setIsAlert] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [msg, setMsg] = useState('')

    const captchaRef = useRef(null);

    const verifyToken = async (token) => {
        try{
        let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/contact/verify_token`,{
        secret:process.env.REACT_APP_SECRET_KEY,    
        token
        });
        // console.log("response =>", response)
        return response.data.data_api;
        }catch(error){
        console.log("error =>",error);
        }
    }
      
      const onSubmit = async (values) => {
          setFormLoading(true);

          try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/contact/add_feedback`, values);

            let token = captchaRef.current.getValue();

            if(token){

                let valid_token = await verifyToken(token);
                if(valid_token.success){

                    if(data?.error){
                        // toast.error('There was an error while submitting. Please try again.');
                        setIsAlert(true);
                        setIsSuccess(false);
                        setMsg('There was an error while submitting. Please try again.');
                        setFormLoading(false);
                      }else {
                        setIsAlert(true);
                        setIsSuccess(true);
                        setMsg('Your Message was successfully sent.');
                        setFormLoading(false);
                    }  

                    }else{
                    //  toast.error("Sorry!! Token invalid");
                     setIsAlert(true);
                     setIsSuccess(false);
                     setMsg('Sorry!!! Token invalid. Please try again.');
                     setFormLoading(false);
                }
            } else {
                // toast.error('You must confirm you are not a robot');
                setIsAlert(true);
                setIsSuccess(false);
                setMsg('You must confirm you are not a robot.');
                setFormLoading(false);
            } 

            // if(data?.error){
            //     toast.error('There was an error while submitting. Please try again.');
            //     setFormLoading(false);
            //   }else {
            //     toast.success('Your Message was successfully sent.');
            //     setFormLoading(false);
            // }  

            setFormLoading(false);
          } catch (err) {
              console.log(err)
              // toast.error('There was an error while submitting. Please try again.');
              setIsAlert(true);
              setIsSuccess(false);
              setMsg('There was an error while submitting. Please try again.');
              setFormLoading(false);
          }
      }

      useEffect(() => {
        if(isAlert){
          const timer = setTimeout(() => setIsAlert(false), 5000);
          return () => clearTimeout(timer);
        }
        return;
      }, [isAlert])


      const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["siteData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/site_settings`)
            .then((res) => res.data),
      });
    
      if (isLoading) return(
        <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
      );
    
      if (error) return "An error has occurred: " + error.message;

  return (
      <div className='container mx-auto'>
    <div className='contactPageMainContainer'>
    <div className='top-flex-container'>
        <div className='flex-item'>
            <div className='iconContainer'>
                <GoLocation     
                    className='icon'
                />
            </div>
            <div className='contentContainer'>
                <h3>Address</h3>
                <p>{data.site_settings.address}</p>
            </div>
        </div>

        <div className='flex-item'>
            <div className='iconContainer'>
                <BsTelephone 
                    className='icon'
                />
            </div>
            <div className='contentContainer'>
                <h3>Phone</h3>
                <p>{data.site_settings.telephone}</p>
            </div>
        </div>

        <div className='flex-item'>
            <div className='iconContainer'>
                <MdOutlineEmail 
                    className='icon'
                />
            </div>
            <div className='contentContainer'>
                <h3>Email</h3>
                <p>{data.site_settings.email}</p>
            </div>
        </div>
    </div>


    <div className='bottom-flex-container'>
        <div className='formContainer'>
            <h2>Contact Us</h2>
            {isAlert && (
                <Notification 
                  success={isSuccess}
                  msg={msg}
                />
              )}
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='contactFormInputContainer'>
                    <label>Full Name</label>  
                    <input
                    type="text"
                    placeholder="Full Name"
                    name="fullname"
                    {...register("fullname", { 
                        required: "Full Name is Required", 
                    })}
                    />
                    <span>{errors.fullname?.message}</span>
                </div>

            <div className='contactFormInputContainer'>
                <label>Email Address</label>
                <input
                type="text"
                placeholder="Email Address"
                name="email"
                {...register("email", {
                    required: "Email is Required",
                    pattern: { value: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/, message: "Invalid email address" },
                })}
                />
                <span>{errors.email?.message}</span>
            </div>

        <div className='contactFormInputContainer'>
        <label>Mobile Number</label>
        <input
          type="number"
          placeholder="Mobile Number"
          name="phone"
          {...register("phone", { 
            required: "Mobile Number is Required",
            pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, message: "Invalid Phone or Mobile Number" }, 
          })}
        />
        <span>{errors.phone?.message}</span>
      </div>

      <div className='contactFormInputContainer'>
        <label>Address</label>
        <input
          type="text"
          placeholder="Address"
          name="address"
          {...register("address", { 
            required: "Address is Required",
            pattern: { value: /^.{0,200}$/, message: "Address must be less than 150 letters" },
          })}
        />
        <span>{errors.address?.message}</span>
      </div>

        <div className='contactFormInputContainer'>
        <label>Your Message</label>
        <textarea
          rows="5" 
          // cols="33"
          placeholder="Your Message"
          name="message"
          {...register("message", { 
            required: "Message is Required", 
            pattern: { value: /^.{5,5000}$/, message: "Message must be between 10 to 5000 letters" },
          })}
        />
        <span>{errors.message?.message}</span>
      </div>
        
    <div className='captchaContainer'>
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}  />
    </div>
      

      <button 
    className='contactFormResetButton contactSubmitButton'
    type='submit' disabled={isSubmitting} >
    {formLoading && <ClipLoader size={20} color="#fff"></ClipLoader>}
      Submit
    </button>

            </form>
        </div>

        <div className='mapContainer'>
        <iframe src={data.site_settings.map_location} width="100%" height="500" style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
        </div>
    </div>
</div>
      </div>

  )
}

export default Contact