import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'
import striptags from 'striptags'
import { useNavigate } from 'react-router-dom';

const Services = () => {
    const navigate = useNavigate();
  
    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["servicesData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/services/all/1`)
            .then((res) => res.data),
      });
    
      if (isLoading) return(
        <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
      );
    
      if (error) return "An error has occurred: " + error.message;

      // console.log("data =>", data)

  return (
    <>
    <section className="insite_page_banner">
    <h1>Services</h1>
</section>
<main id="main">
    {/* <section className="inner-page">
      <div className="container">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
    </section> */}

    <section id="services" className="services">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h1 className="main_title">What we do...</h1>
          <p>
            PCS is directed towards IT initiatives that companies wish to take up in the quest to sustain and grow the business. With specialist knowledge and experience across many different market sectors, PCS can confidently deliver the right solution for you – now and for the future.
          </p>
        </div>
        <div className="row">
          {data?.items.map((i) => (
          <div key={i.id} className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">

          <div className="services_box" data-aos="fade-up" data-aos-delay="100" onClick={() => navigate(`/services/${i.slug}`)}>
              <div className="services_img">
                  <img src={i.featured_image} alt={i.slug} />
              </div>
              <div className="services_text">
                <h4>
                  {i.title}
                  </h4>
                <p className="description" dangerouslySetInnerHTML={{ __html: striptags(i.description) }}></p>
              </div>
            </div>
          </div>
          ))}

        </div>
      </div>
    </section>
</main>
</>
  )
}

export default Services