import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'
import striptags from 'striptags'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

const BlogList = () => {
    const navigate = useNavigate();
  
    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["blogData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/blog/all/1`)
            .then((res) => res.data),
      });
    
      if (isLoading) return(
        <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
      );
    
      if (error) return "An error has occurred: " + error.message;

  return (
    <>
    <section className="insite_page_banner">
    <h1>Blogs</h1>
    </section>
    <main id="main">
    <div className='row container mx-auto my-4'>
    {data.items.map((i, index) => 
            <div key={index} className="col-md-3">
            <Card  className="m-2">
            <Card.Img variant="top" src={i.featured_image} alt={i.slug} />
            <Card.Body>
              <Card.Title>{i.title}</Card.Title>
              <Card.Text dangerouslySetInnerHTML={{ __html: striptags(i.description.substring(0, 100)) }}>
              </Card.Text>
              <Button onClick={() => navigate(`/blogs/${i.slug}`)} variant="success">Read More</Button>
            </Card.Body>
          </Card>
          </div>
      )}
    
    </div>
    </main>
    </>
  )
}

export default BlogList