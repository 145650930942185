import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import striptags from 'striptags'
import {useNavigate} from 'react-router-dom'

function BlogHome({data}) {
  const navigate = useNavigate();

  return (
    <section id="blogs" className='services'>
      <div className='col-md-11 mx-auto'>
      <div className="section-title" data-aos="fade-up">
        <h1 className="main_title" style={{ textAlign: 'center', lineHeight: '5px' }}>Latest Articles</h1>
      </div>
    
    <div className='row'>
      {data.map((i, index) => 
            <div key={index} className="col-md-3">
            <Card  className="m-2">
            <Card.Img variant="top" src={i.featured_image} alt={i.slug} />
            <Card.Body>
              <Card.Title>{i.title}</Card.Title>
              <Card.Text dangerouslySetInnerHTML={{ __html: striptags(i.description.substring(0, 100)) }}>
              </Card.Text>
              <Button onClick={() => navigate(`/blogs/${i.slug}`)} variant="success">Read More</Button>
            </Card.Body>
          </Card>
          </div>
      )}



    {/* <div className='col-md-3'>
    <Card className="m-2">
      <Card.Img variant="top" src="https://nyatapol.biz/PCS/dashprisallc/uploads/responsive_filemanager/gallery/blogs/2650401.jpg" />
      <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <Button variant="success">Go somewhere</Button>
      </Card.Body>
    </Card>
    </div>

    <div className='col-md-3'>
    <Card className="m-2">
      <Card.Img variant="top" src="https://nyatapol.biz/PCS/dashprisallc/uploads/responsive_filemanager/gallery/blogs/2650401.jpg" />
      <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <Button variant="success">Go somewhere</Button>
      </Card.Body>
    </Card>
    </div>

    <div className='col-md-3'>
    <Card className="m-2">
      <Card.Img variant="top" src="https://nyatapol.biz/PCS/dashprisallc/uploads/responsive_filemanager/gallery/blogs/2650401.jpg" />
      <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <Button variant="success">Go somewhere</Button>
      </Card.Body>
    </Card>
    </div> */}


    </div>


      </div>
    </section>

  );
}

export default BlogHome;