import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Notification from '../components/Notification';
// import {toast} from 'react-toastify'
// import {ClipLoader} from 'react-spinners'

const Apply = () => {
  const {id} = useParams();

  const { register, handleSubmit, reset, formState: { errors }, formState } = useForm();
  const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
  const [formLoading, setFormLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [msg, setMsg] = useState('');
  const [convert, setConvert] = useState();
  console.log("convert =>", typeof convert, convert);
  // const [values, setValues] = useState(null)

  // useEffect(() => {
  //     setValues({...values, career_id: id});
  // }, [id])

  // console.log("values =>", values);

  useEffect(() => {
    if(isAlert){
      const timer = setTimeout(() => setIsAlert(false), 5000);
      return () => clearTimeout(timer);
    }
    return;
  }, [isAlert])

  const convert2base64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setConvert(reader.result.toString());

      console.log("reader =>", reader.result.toString());
    };

    reader.readAsDataURL(file);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("file =>", file)
    convert2base64(file);
  }

  const onSubmit = async (values) => {
    setFormLoading(true); 
    console.log("valuesss =>", {...values, career_id: id});
    
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/application_form`, {...values, career_id: id, resume: convert });
      if(data?.error){
        setIsAlert(true);
        setIsSuccess(false);
        setMsg('There was an error while submitting. Please try again.');
        setFormLoading(false);
      }else {
        setIsAlert(true);
        setIsSuccess(true);
        setMsg('Your Message was successfully sent.');
        setFormLoading(false);
    } 
    } catch (err) {
        console.log("err =>", err);
        setIsAlert(true);
        setIsSuccess(false);
        setMsg('There was an error while submitting. Please try again.');
        setFormLoading(false);
    }
}

  return (
    <main id="main">
    <section className="inner-page" style={{ marginTop: '50px' }}>
      <div className="container">
        <div className="career-form">

        {isAlert && (
          <Notification 
            success={isSuccess}
            msg={msg}
          />
        )}

          <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="first_name" 
                      {...register("first_name", { 
                        required: "First Name is Required", 
                      })}
                    />
                    <span>{errors.first_name?.message}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Middle Name</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="middle_name"
                      {...register("middle_name")} 
                    />
                    <span>{errors.middle_name?.message}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="last_name" 
                      {...register("last_name", { 
                        required: "Last Name is Required", 
                      })}
                    />
                    <span>{errors.last_name?.message}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Email</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="email"
                      {...register("email", {
                        required: "Email is Required",
                        pattern: { value: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/, message: "Invalid email address" },
                      })}
                    /> 
                    <span>{errors.email?.message}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Address</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="address" 
                      {...register("address", { 
                        required: "Address is Required", 
                      })}                    
                    />
                    <span>{errors.address?.message}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input 
                      className="form-control" 
                      type="number" 
                      name="phone" 
                      {...register("phone", { 
                        required: "Phone Number is Required",
                        pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, message: "Invalid Phone or Mobile Number" }, 
                      })}
                    />
                    <span>{errors.phone?.message}</span>
                  </div>
                </div>
                
                {/* <div className="col-sm-4">
                  <div className="form-group">
                    <label>Career Id</label>
                    <input 
                      className="form-control" 
                      disabled={true}
                      type="text" 
                      name="career_id" 
                      value={id}
                      {...register("career_id")}                    
                    />
                  </div>
                </div> */}

                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Attach Resume</label>
                    <input onChange={(e) => handleFileChange(e)} className="form-control" type="file" name="resumeTest" required />                     
                    {/* <span>{errors.resumeTest?.message}</span> */}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <button className="apply-btn" type='submit' disabled={isSubmitting}>Apply now</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
      </div>
    </section>
</main>
  )
}

export default Apply