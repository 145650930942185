import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {ClipLoader} from 'react-spinners'
// import { VscFilePdf } from 'react-icons/vsc';

const PrivacyPolicy = () => {

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["siteData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/site_settings`)
        .then((res) => res.data),
  });

  if (isLoading) return(
    <div className="loaderContainer"><ClipLoader size={100} color='#d82d2b' loading /></div>
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
    <section className="insite_page_banner">
    <h1>Privacy Policy</h1>
</section>
<main id="main">
    <section className="inner-page">
      <div className="container">
        <div className='ckContent' dangerouslySetInnerHTML={{ __html: data.privacy_policy.description }}></div>

        {/* <a href={data.privacy_policy.DocPath} target="_blank" className='downloadsPageResultItem'>
      <p className='downloadsPageResultText'>View / Download File</p>
      <VscFilePdf color='#3a3a3a' size="25px" />
      </a> */}
      
      </div>
    </section>
</main>
</>
  )
}

export default PrivacyPolicy